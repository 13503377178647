import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { Section, Button } from '../../styled-components';
import Link from '../link';
import why1 from '../../images/why-1.png';
import why2 from '../../images/why-2.png';
import why3 from '../../images/why-3.png';
import WhyImg from '../../images/brand-icon-half.png';
import windowImg from '../../images/window.png';
import SquareImg from '../../images/square.png';
import circleImg from '../../images/circle.png';
import buildingImg from '../../images/building.png';

const Title = styled.h2`
  //font-size: 1.5rem;
  //color: ${props => props.theme.primaryColor};
  text-align: center;
  margin-bottom: 2rem;
  @media(min-width: 768px){
    max-width: 50%;
  }
`
const BackPiece = styled.img`
  position: absolute;
  width: ${props => props.width ? props.width : "auto"};
  height: ${props => props.height ? props.height : "auto"};
  top: ${props => props.top ? props.top : "auto"};
  left: ${props => props.left ? props.left : "auto"};
  right: ${props => props.right ? props.right : "auto"};;
  bottom: ${props => props.bottom ? props.bottom : "auto"};
  object-fit: cover;
  object-position: center;
`

const Red = styled.span`
  color: ${props => props.theme.primaryColor};  
`
const InfoCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Paragraph = styled.p`
  width: 70%;
  text-align: center;
`
const ExtraCont = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6rem 0 4rem;
  flex-direction: column;
  @media(min-width: 768px){
    flex-direction: row;
  }
`
const ExtraItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  @media(min-width: 768px){
    width: 25%;
    margin-bottom: 0rem;
  }
`
const Icon = styled.img`
  max-width: 80px;
  min-height: 65px;
  margin-bottom: 2rem;
`
const ExtraInfo = styled.p`
  text-align: center;
`
const Bold = styled.span`
  font-weight: bold;
`
const WhyImage = styled.div`
  background-image: url("${WhyImg}");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: .3;
  height: 100%;
`
const WhyCont = styled.div`
  
`
const WhyInfoCont = styled.div`
  
`
const WhyTitle = styled(Title)`
  text-align: left;
  margin-bottom: 2rem;
`
const WhySubTitle = styled(Bold)`
  border-bottom: 2px solid hsl(3, 55%, 85%);
  line-height: 2rem;
  text-transform: uppercase;
`
const WhyParagraph = styled(Paragraph)`
  text-align: left;
  margin-bottom: 2rem;
`
const WhyList = styled.ol`
  font-size: .8rem;
  list-style: none;
`
const WhyItem = styled.li`

`
const ContactCont = styled.div`
  text-align: center;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ButtonCustom = styled(Button)`
  padding: 0 24px;
`
const SubTitle = styled.p`
  font-size: 1.25rem;
  margin-bottom: 3rem;
  @media(min-width: 768px){
    width: 70%;
  }
`

export default ()=> {

  return(
    <Section>
      <BackPiece
        src={windowImg}
        alt=""
        top="-50px"
        left="10px"
        width="120px"
      />
      <BackPiece
        src={circleImg}
        alt=""
        top="50%"
        right="10px"
        width="120px"
      />
      <Container>
        <InfoCont>
          <Title>
            Combinamos la experiencia inmobiliaria, la <Red>digitalización</Red> y el <Red>marketing de red</Red>
          </Title>
          {/* <Paragraph>
          Digitalizamos la agencia inmobiliaria tradicional y queremos que tú seas parte de una
experiencia que puede cambiar tu forma de trabajar.
          </Paragraph>
          <Paragraph>
          Conviértete en un agente TradeHouse, supone entrar a nuestra red inmobiliaria que te
entregará todas las herramientas, la tecnología y el soporte que necesitas, para desarrollar
toda tu capacidad en el mundo de corretaje de propiedades.
          </Paragraph>          
          <Paragraph>
          Contamos con todo el soporte, capacitación, valorizaciones comerciales online, alianzas
estratégicas y todo lo necesario para potenciar tu carrera profesional.
          </Paragraph> */}
        </InfoCont>
        <ExtraCont>
          <ExtraItem>
            <Icon src={why1} alt="" />
            <ExtraInfo>
              <Bold>Potencia</Bold> tu carrera.
            </ExtraInfo>
          </ExtraItem>
          <ExtraItem>
            <Icon src={why2} alt="" />
            <ExtraInfo>
              <Bold>Optimiza</Bold> tus tiempos.
            </ExtraInfo>
          </ExtraItem>
          <ExtraItem>
            <Icon src={why3} alt="" />
            <ExtraInfo>
              <Bold>Aumenta</Bold> tus ingresos.
            </ExtraInfo>
          </ExtraItem>                    
        </ExtraCont>  
        <ContactCont>
          <SubTitle>
            Conviértete en un agente TradeHouse y disfruta la experiencia de pertenecer a una red que te permitirá desarrollar tu propio negocio
          </SubTitle> 
          <Paragraph>
            ¿Quieres unirte al equipo de TradeHouse?
          </Paragraph>
          <br />
          <Link to="/contact-agents">
            <ButtonCustom primary>
              Completa la siguiente solicitud
            </ButtonCustom>
          </Link>
        </ContactCont>     
      </Container>
    </Section>
  )
}